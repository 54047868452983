<template>
  <div class="header-wrapper">
    <div class="logo-wrapper">
      <router-link to="/index">
        <img src="../../assets/imgs/logo.png" alt="" />
      </router-link>
    </div>
    <div class="nav-wrapper" v-if="!ifHideNav">
      <!-- <i
        class="iconfont icon-xinxitishi"
        style="
          position: relative;
          top: 2px;
          cursor: pointer;
          margin-right: 10px;
        "
        @click="showNewVersionNotification"
      ></i> -->
      <div
        class="nav-item"
        v-for="navItem in navList"
        :key="navItem.name"
        @click="navItem.handleClick"
      >
        <i :class="['iconfont', navItem.icon, 'nav-icon']"></i>
        <span class="nav-text">{{ navItem.name }}</span>
      </div>
      <div class="nav-item" style="min-width: 120px; width: auto">
        <el-dropdown>
          <span>
            <i class="iconfont icon-xiazai nav-icon"></i>
            <span style="position: relative; top: 0px">{{ username }}</span>
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                @click="goUserCenter('https://i.ringdata.com/index')"
              >
                个人中心
              </el-dropdown-item>
              <el-dropdown-item @click="goUserEquity">
                个人权益
              </el-dropdown-item>
              <el-dropdown-item @click="userExit">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <el-dialog
      v-model="newVersionDLGVisible"
      title="提示"
      :modal="false"
      width="600px"
      append-to-body
    >
      <div style="line-height: 30px; font-size: 16px">
        <p>尊敬的锐研·云采集用户，您好！</p>
        <p style="text-indent: 2em; line-height: 26px; text-align: justify">
          为了更好地支持用户进行数据采集，我们已于2024年3月30日发布V3.0新版；新版本云采集对系统交互和界面进行了优化处理，用户体验全面提升，如您在使用新版本过程中有任何问题，请扫描下方二维码添加客服微信咨询！感谢您的使用与支持！
          截止至2024年6月30日，我们将依旧支持旧版云采集的访问与使用，地址为：
          <a href="https://cj.ringdata.com/old/" style="color: blue">
            https://cj.ringdata.com/old/
          </a>
        </p>
        <div style="display: flex; justify-content: space-around">
          <img
            src="../../assets/imgs/customerQRCode.jpg"
            width="180"
            height="180"
            style="margin: auto; margin-top: 20px"
          />
        </div>

        <p align="right" style="line-height: 30px">锐研数据</p>
        <p align="right" style="line-height: 30px">2024年3月30日</p>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineComponent, reactive, ref, defineProps, onMounted } from "vue";
import VueCookies from "vue-cookies";
import { useRouter } from "vue-router";
import { httpPost } from "@/api/httpService";
import { ElMessage } from "element-plus";
import { loadIframe } from "@/utils/loadIframe";
const props = defineProps({
  ifHideNav: {
    default: false,
  },
});

const router = useRouter();
const navList = ref([
  {
    name: "插件下载",
    icon: "icon-zhinengfenxi-toubu-PC",
    handleClick: () => {
      // router.push({ path: "/pricing" });
      window.open("/plugin/download");
    },
  },
  {
    name: "帮助中心",
    icon: "icon-wenhao",
    handleClick: () => {
      window.open("https://help.ringdata.com/project/caiji");
    },
  },

  {
    name: "意见与反馈",
    icon: "icon-quanju_fankui",
    handleClick: () => {
      window.open(
        "https://www.ringdata.com/feedback?productCategory=2&productType=3"
      );
    },
  },
  // {
  //   name: "帮助中心",
  //   icon: "icon-help"
  // }
]);
const username = ref("");

const newVersionDLGVisible = ref(false);
let userInfo = VueCookies.get("userInfo");
if (userInfo) {
  username.value = userInfo.name;
}

const goUserCenter = (value) => {
  window.open(value);
};

//进入个人权益
const goUserEquity = () => {
  router.push("/user/equity");
};

//用户退出
const userExit = () => {
  let { userId } = VueCookies.get("userInfo");
  httpPost("/centerAuth/v1/exit").then((res) => {
    VueCookies.remove("tokenWeb");
    VueCookies.remove("userInfo");
    VueCookies.remove("tokenWebRefresh");
    VueCookies.remove("tokenWeb", "/", "ringdata.com");
    VueCookies.remove("userInfo", "/", "ringdata.com");
    VueCookies.remove("tokenWebRefresh", "/", "ringdata.com");
    window.sessionStorage.setItem("ifLoginSPIDER_" + userId, false);

    if (res && res.code === 0) {
      ElMessage({
        message: "退出成功",
        type: "success",
      });

      // window.websocket.onclose();
      // window.sessionStorage.setItem("socketKey", "N");
      // this.loginOut();
      setTimeout(() => {
        router.push("/index");
        // 跨域免登录
        loadIframe("https://www.ringdata.com/freeLogin");
      }, 100);
    } else if (res) {
      ElMessage.error(res.message);
    }
  });
};
const showNewVersionNotification = () => {
  // console.log("show new version");
  // newVersionDLGVisible.value = true;
};
onMounted(() => {
  // showNewVersionNotification();

  if (!window.localStorage.getItem("cj_ifShowedNewVersionNotification")) {
    showNewVersionNotification();
    window.localStorage.setItem("cj_ifShowedNewVersionNotification", true);
  }
});
</script>

<style lang="scss" scoped>
.header-wrapper {
  height: $header-height;
  display: flex;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.4);
  z-index: 2000;
  .logo-wrapper {
    width: $header-logo-width;

    a {
      height: $header-height;
      display: block;
      display: flex;
      flex-direction: column;
      img {
        // height: $header-logo-height;
        height: 40px;
        width: 136px;
        margin: auto;
      }
    }
  }
  .nav-wrapper {
    $nav-item-width: 120px; //头部导航栏单个导航宽度
    display: inline-block;
    .nav-item {
      display: inline-block;
      width: $nav-item-width;
      height: 100%;
      padding: 10px;
      color: #606266;
      @include pointer();
      // 帮助垂直居中
      @include inline-block-middle();
      //   &:before {
      //     content: "";
      //     display: inline-block;
      //     vertical-align: middle;
      //     height: 100%;
      //   }
      .nav-icon {
        vertical-align: middle;
        margin-right: 10px;
      }
      .nav-text {
        vertical-align: middle;
      }
      .el-dropdown {
        vertical-align: middle;
      }
      .el-icon--right {
        position: relative;
        top: 2px;
      }
    }
  }
}
</style>
