//字段类型的常量
const FIELD_TYPE = {
  STRING: "string",
  INT: "int",
  FLOAT: "float",
  DATETIME: "datetime",
  TEXT: "text",
  LONG_TEXT: "longText",
};
const FIELD_TYPE_MAP = {
  [FIELD_TYPE.STRING]: { value: "string", label: "字符串" },
  [FIELD_TYPE.INT]: { value: "int", label: "整数" },
  [FIELD_TYPE.FLOAT]: { value: "float", label: "小数" },
  [FIELD_TYPE.DATETIME]: { value: "datetime", label: "日期" },
  [FIELD_TYPE.TEXT]: { value: "text", label: "文本" },
  [FIELD_TYPE.LONG_TEXT]: { value: "longText", label: "长文本" },
};
const FIELD_TYPE_OPTIONS = Object.keys(FIELD_TYPE_MAP).map((key) => ({
  label: FIELD_TYPE_MAP[key].label,
  value: key,
}));

export { FIELD_TYPE, FIELD_TYPE_MAP, FIELD_TYPE_OPTIONS };
